import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { ProductImageType } from "components/organisms/ImageSelector/ImageSelector";

export interface BasicProductFormData {
  title: string;
  description: string;
  vendor: string;
  setOf: string;
  category: string;
  subCategory: string;
  productType: string;
  tags: string[];
  selectedImages: (ProductImageType | File)[];
  sizeChartImage: ProductImageType | File | undefined;
  removedImages: number[];
  specificDetails: Record<string, SpecificDetail>;
}

export interface SpecificDetail {
  key: string;
  namespace: string;
  value: string | null;
}

export interface SpecificDetailOption {
  key: string;
  namespace: string;
  data_type: string;
  label: string;
  has_preset_values: boolean;
  master_values: string[] | null;
}

export interface VariantRowData {
  variantName: string;
  skuId: string;
  costPrice: number;
  mrp: number;
  listingPrice: number;
  stock: number;
}

export interface VariantData {
  variantType: string;
  variantRowData: VariantRowData[];
}

export interface ProductFormData {
  basicDetails: BasicProductFormData;
  variantDetails: VariantData;
}

export interface variantPricing {
  mrp: number;
  listingPrice: number;
  costPerItem: number;
  gst: number;
}

export interface variantInventory {
  skuID: string;
  hsnCode: string;
  stockOnHand: number;
  vin: string;
  isFBV: false;
  trackQuantity: false;
  sellOnOOS: false;
}

export interface variantMetafield {
  upsell_product_variants: string;
  measurement: string;
  includes: string;
  old_price: number;
}

export interface ProductVariantFormData {
  variantName: string;
  variantImage: string;
  variantPricing: variantPricing;
  variantInventory: variantInventory;
  metafields: variantMetafield;
}

export interface ProductDetailsData {
  product: {
    id: number;
    title: string;
    description: string;
    primary_images: [{ id: number; src: string }];
    size_chart_image: {};
    vendor: {
      id: number;
      name: string;
    };
    set_of: {
      id: number | null;
      name: string | null;
    };
    category: {
      id: number;
      name: string;
    };
    sub_category: {
      id: number;
      name: string;
    };
    product_types: {
      id: number;
      product_type: string;
    };
    tags: {
      id: number;
      name: string;
    }[];
    variants: {
      id: number;
      variantName: string;
      skuId: string;
      mrp: string;
      listingPrice: string;
      stock: number;
      gst: number;
      vin: string;
    }[];
    status: string;
    variant_type: string;
    collections: string[];
  };
}

// Common function to make API calls
const fetchApiData = async (url: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", headers: any, requestData: any = {}, rejectWithValue: any) => {
  const config: AxiosRequestConfig = {
    headers: headers || {},
    method,
    url,
    data: requestData,
  };
  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 401) {
      return rejectWithValue({ errors: "Unauthorized! Login again" });
    }
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
};

// Fetch Vendor Details (GET)
export const fetchVendorDetails = createAsyncThunk("productForm/fetchVendorDetails", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_vendors";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Fetch Categories (GET)
export const fetchCategories = createAsyncThunk("productForm/fetchCategories", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_categories";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Fetch Subcategories (GET)
export const fetchSubcategories = createAsyncThunk("productForm/fetchSubcategories", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_sub_categories";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Fetch Product Types (GET)
export const fetchProductTypes = createAsyncThunk("productForm/fetchProductTypes", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_product_types";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Fetch Set Pieces (GET)
export const fetchSetPieces = createAsyncThunk("productForm/fetchSetPieces", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_set_pieces";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Fetch Colors (GET)
export const fetchColors = createAsyncThunk("productForm/fetchColors", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_colors";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});
// Fetch Return Policies (GET)
export const fetchReturnPolicies = createAsyncThunk("productForm/fetchReturnPolicies", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_return_policies";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});
// Fetch Materials (GET)
export const fetchMaterials = createAsyncThunk("productForm/fetchMaterials", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_materials";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});
// Fetch Tags (GET)
export const fetchTags = createAsyncThunk("productForm/fetchTags", async ({ headers }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/get_tags";
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Create a New Product (POST)
export const createProduct = createAsyncThunk("productForm/createProduct", async ({ headers, formData }: any, { rejectWithValue }) => {
  const url = "/solomon/products";
  return await fetchApiData(url, "POST", headers, formData, rejectWithValue);
});

export const submitProductForm = createAsyncThunk("productForm/createProduct", async ({ headers, formData }: any, { rejectWithValue }) => {
  const config: AxiosRequestConfig = {
    headers: headers || {},
  };
  try {
    const response = await axiosInstance.post(`/solomon/products`, formData, config);
    const resData = response.data;

    return resData;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 401) {
      return rejectWithValue({ errors: "Unauthorized! Login again" }); // Capture 401 in rejected state
    }
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;

      return rejectWithValue(errorResponse);
    }

    throw error;
  }
});
// Fetch Product Details by ID (GET)
export const fetchProductDetails = createAsyncThunk("productForm/fetchProductDetails", async ({ id, headers }: { id: string; headers: any }, { rejectWithValue }) => {
  const url = `/solomon/products/${id}/edit`;
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

// Update an Existing Product (PATCH)
export const updateProduct = createAsyncThunk("productForm/updateProduct", async ({ id, headers, formData }: any, { rejectWithValue }) => {
  const url = `/solomon/products/${id}`;
  return await fetchApiData(url, "PATCH", headers, formData, rejectWithValue);
});

// Update Product Variant (PATCH)
export const updateProductVariant = createAsyncThunk("productForm/updateProductVariant", async ({ id, headers, formData }: any, { rejectWithValue }) => {
  const url = `/solomon/product_variants/${id}`;
  return await fetchApiData(url, "PATCH", headers, formData, rejectWithValue);
});

export const getSpecificDetailOptions = createAsyncThunk("productForm/specifiDetailOptions", async ({ headers, formData }: any, { rejectWithValue }) => {
  const url = `/solomon/products/get_generic_details?sub_category_id=${formData.sub_category_id}`;
  return await fetchApiData(url, "GET", headers, formData, rejectWithValue);
});


// Define the initial state
interface ProductFormState {
  vendorDetails: any[];
  categories: any[];
  subcategories: any[];
  productTypes: any[];
  setPieces: any[];
  colors: any[];
  returnPolicies: any[];
  materials: any[];
  tags: any[];
  loading: boolean;
  productDetails: any;
  error: string | null;
  specifiDetailOptions: SpecificDetailOption[];
}

const initialState: ProductFormState = {
  vendorDetails: [],
  categories: [],
  subcategories: [],
  productTypes: [],
  setPieces: [],
  colors: [],
  returnPolicies: [],
  materials: [],
  tags: [],
  loading: false,
  productDetails: {},
  specifiDetailOptions: [],
  error: null,
};

const productFormSlice = createSlice({
  name: "productForm",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.vendorDetails = [];
      state.categories = [];
      state.subcategories = [];
      state.productTypes = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Vendor Details Fetching
    builder.addCase(fetchVendorDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchVendorDetails.fulfilled, (state, action: PayloadAction<any>) => {
      state.vendorDetails = action.payload.vendors;
      state.loading = false;
    });
    builder.addCase(fetchVendorDetails.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch vendor details";
      state.loading = false;
    });

    // Categories Fetching
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<any>) => {
      state.categories = action.payload.categories;
      state.loading = false;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch categories";
      state.loading = false;
    });

    // Subcategories Fetching
    builder.addCase(fetchSubcategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubcategories.fulfilled, (state, action: PayloadAction<any>) => {
      state.subcategories = action.payload.sub_categories;
      state.loading = false;
    });
    builder.addCase(fetchSubcategories.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch subcategories";
      state.loading = false;
    });

    // Product Types Fetching
    builder.addCase(fetchProductTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProductTypes.fulfilled, (state, action: PayloadAction<any>) => {
      state.productTypes = action.payload.product_types;
      state.loading = false;
    });
    builder.addCase(fetchProductTypes.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch product types";
      state.loading = false;
    });

    // Set Pieces Fetching
    builder.addCase(fetchSetPieces.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSetPieces.fulfilled, (state, action: PayloadAction<any>) => {
      state.setPieces = action.payload.set_pieces;
      state.loading = false;
    });
    builder.addCase(fetchSetPieces.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch set pieces";
      state.loading = false;
    });
    // Colors Fetching
    builder.addCase(fetchColors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchColors.fulfilled, (state, action: PayloadAction<any>) => {
      state.colors = action.payload.colors;
      state.loading = false;
    });
    builder.addCase(fetchColors.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch colors";
      state.loading = false;
    });
    // Return Policies Fetching
    builder.addCase(fetchReturnPolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchReturnPolicies.fulfilled, (state, action: PayloadAction<any>) => {
      state.returnPolicies = action.payload.return_policies;
      state.loading = false;
    });
    builder.addCase(fetchReturnPolicies.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch return policies";
      state.loading = false;
    });

    // Materials Fetching
    builder.addCase(fetchMaterials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMaterials.fulfilled, (state, action: PayloadAction<any>) => {
      state.materials = action.payload.materials;
      state.loading = false;
    });
    builder.addCase(fetchMaterials.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch set pieces";
      state.loading = false;
    });

    // Tags Fetching
    builder.addCase(fetchTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTags.fulfilled, (state, action: PayloadAction<any>) => {
      state.tags = action.payload.materials;
      state.loading = false;
    });
    builder.addCase(fetchTags.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch set pieces";
      state.loading = false;
    });

    // Product Details Fetching
    builder.addCase(fetchProductDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProductDetails.fulfilled, (state, action: PayloadAction<any>) => {
      state.productDetails = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchProductDetails.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch set pieces";
      state.loading = false;
    });

    builder.addCase(getSpecificDetailOptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSpecificDetailOptions.fulfilled, (state, action: PayloadAction<{specific_details: SpecificDetailOption[]}>) => {
      state.specifiDetailOptions = action.payload.specific_details || [];
      state.loading = false;
    });
    builder.addCase(getSpecificDetailOptions.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch specific details";
      state.loading = false;
    });
  },
});

// Export actions and reducer
export const { resetForm } = productFormSlice.actions;
export default productFormSlice.reducer;
