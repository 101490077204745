// filtersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProductCatalogueStatusType = 'all' | 'active' | 'draft' | 'archived';

interface FiltersState {
  productCatalogueStatusFilter: ProductCatalogueStatusType;
  productCatalogueCategoryFilter: string;
  productCatalogueSubCategoryFilter: string;
  productCatalogueVendorFilter: string;
  productCatalogueTitleFilter: string;
  productCatalogueSkuFilter: string;
  productCatalogueVinFilter: string;
  productCatalogueHsnCodeFilter: string;
  productCatalogueFbvEnabledFilter: boolean;
  productCatalogueCreatedAtStartDateFilter: Date | undefined;
  productCatalogueCreatedAtEndDateFilter: Date | undefined;
  productCatalogueInventoryFilter: string;
  collectionCatalogueSkuFilter: string;
  collectionCatalogueCollectionNameFilter: string;
  collectionCatalogueCollectionTypeFilter: string;
  collectionCatalogueCreatedAtStartDateFilter: Date | undefined;
  collectionCatalogueCreatedAtEndDateFilter: Date | undefined;
}

export const initialState: FiltersState = {
  productCatalogueStatusFilter: 'all',
  productCatalogueCategoryFilter: '',
  productCatalogueSubCategoryFilter: '',
  productCatalogueVendorFilter: '',
  productCatalogueTitleFilter: '',
  productCatalogueSkuFilter: '',
  productCatalogueVinFilter: '',
  productCatalogueHsnCodeFilter: '',
  productCatalogueFbvEnabledFilter: false,
  productCatalogueCreatedAtStartDateFilter: undefined,
  productCatalogueCreatedAtEndDateFilter: undefined,
  productCatalogueInventoryFilter: '',
  collectionCatalogueSkuFilter: '',
  collectionCatalogueCollectionNameFilter: '',
  collectionCatalogueCollectionTypeFilter: '',
  collectionCatalogueCreatedAtStartDateFilter: undefined,
  collectionCatalogueCreatedAtEndDateFilter: undefined,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setProductCatalogueStatusFilter: (state, action: PayloadAction<ProductCatalogueStatusType>) => {
      state.productCatalogueStatusFilter = action.payload;
    },
    setProductCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueCategoryFilter = action.payload;
    },
    setProductCatalogueSubCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSubCategoryFilter = action.payload;
    },
    setProductCatalogueVendorFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVendorFilter = action.payload;
    },
    setProductCatalogueTitleFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueTitleFilter = action.payload;
    },
    setProductCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSkuFilter = action.payload;
    },
    setProductCatalogueVinFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVinFilter = action.payload;
    },
    setProductCatalogueHsnCodeFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueHsnCodeFilter = action.payload;
    },
    setProductCatalogueFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.productCatalogueFbvEnabledFilter = action.payload;
    },
    setproductCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setproductCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setProductCatalogueInventoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueInventoryFilter = action.payload;
    },
    setCollectionCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueSkuFilter = action.payload;
    },
    setCollectionCatalogueCollectionNameFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueCollectionNameFilter = action.payload;
    },
    setCollectionCatalogueCollectionTypeFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueCollectionTypeFilter = action.payload;
    },
    setcollectionCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.collectionCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setcollectionCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.collectionCatalogueCreatedAtEndDateFilter = action.payload;
    },
    resetFilter: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setProductCatalogueStatusFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueSubCategoryFilter,
  setProductCatalogueVendorFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueSkuFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueHsnCodeFilter,
  setProductCatalogueFbvEnabledFilter,
  setproductCatalogueCreatedAtStartDateFilter,
  setproductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueInventoryFilter,
  setCollectionCatalogueSkuFilter,
  setCollectionCatalogueCollectionNameFilter,
  setCollectionCatalogueCollectionTypeFilter,
  setcollectionCatalogueCreatedAtStartDateFilter,
  setcollectionCatalogueCreatedAtEndDateFilter,
  resetFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
