import ModalBox from "components/atoms/ModalBox/ModalBox";
import styles from "./ProductSearch.module.scss";
import { Box, Checkbox, Radio } from "@mui/material";
import InputX from "components/atoms/InputX/InputX";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { ProductType, searchProductsApi } from "slices/productSearchSlice";
import Img from "components/atoms/Img/Img";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import { resetAuth } from "slices/authSlice";
import Loader from "components/atoms/Loader/Loader";

type ProductSearchPropsType = {
  title?: string;
  multiselect: boolean;
  open: boolean;
  onClose: () => void;
  onChange: (values:  ProductType[]) => void;
}

type ProductItemPropType = ProductType & {
  multiselect: boolean;
  selected: boolean;
  onSelect: (id: string | number) => void;
}

const ProductItem:React.FC<ProductItemPropType> = (props) => {
  const {selected, multiselect, id, image, title, onSelect} = props;

  return (
    <Box key={id} className={styles.productItem}>
      {multiselect ? (
        <Checkbox checked={selected} className={styles.checkbox} onChange={() => {onSelect(id)}}/>
      ): (
        <Radio checked={selected} className={styles.radio} value={id} onChange={() => onSelect(id)} />
      )}
      <Img src={image || undefined} alt={title} className={styles.productImg} />
      <p className={styles.productTitle}>{title}</p>
    </Box>
  );
}

const ProductSearch:React.FC<ProductSearchPropsType> = (props) => {
  const dispatch = useAppDispatch();
  const {title = 'Add Products', multiselect, open, onClose, onChange} = props;
  const [searchTxt, setSearchTxt] = useState('');
  const [selectedProductIds, setSelectedProductIds] = useState<(string|number)[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const searchResults = useAppSelector((state: RootState) => state.searchProduct.searchResults)?.data || [];
  const apiStatus = useAppSelector((state: RootState) => state.searchProduct.searchProductsApiStatus);

  useEffect(() => {
    if(open){
      setSelectedProductIds([]);
      setSearchTxt('');
      setFilterApplied(true);
    }
  }, [open]);

  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        searchProductsApi({
          searchTxt: searchTxt.trim(),
          headers,
        })
      ).unwrap();
      setFilterApplied(false);
    } else {
      dispatch(resetAuth());
    }
  }

  useEffect(() => {
    if(filterApplied){
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const handleSearch = () => {
    setSelectedProductIds([]);
    setFilterApplied(true);
  }

  const onSearchTxt = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {nativeEvent: {key}} = e;
    if(key === 'Enter'){
      handleSearch();
    }
  }

  const handleSelection = (id: string|number) => {
    if(multiselect){
      setSelectedProductIds((prevIds) =>{
        if(prevIds.includes(id)){
          return prevIds.filter((_id) => _id !== id)
        }else{
          return [...prevIds, id]
        }
      });
    }else{
      setSelectedProductIds([id]);
    }
  }

  const onAdd = () => {
    onChange(searchResults.filter((product) => {
      return selectedProductIds.includes(product.id)
    }));
    onClose();
  }

  const onClear = () => {
    setSelectedProductIds([]);
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles.productSearchModalBox}
    >
     <>
      <Box className={styles.header}>
        <p className={styles.title}>{title}</p>
        <CloseIcon onClick={onClose} className={styles.closeIcon} />
      </Box>
      <InputX
        value={searchTxt}
        placeholder="Search products / SKU ID"
        onChange={(e) => setSearchTxt(e.target.value)}
        onKeyDown={onSearchTxt}
      />

      <Box className={styles.searchResults}>
        <Loader show={apiStatus === 'loading'} />
        {apiStatus !== 'loading' && searchResults.map((product) => {
          return <ProductItem
            key={product.id}
            {...product}
            selected={selectedProductIds.includes(product.id)}
            multiselect={multiselect}
            onSelect={handleSelection}
          />
        })}
      </Box>
      <Box className={styles.actionBar}>
        {multiselect && <p className={styles.productCount}>{`${selectedProductIds.length} product selected`}</p>}
        <Box className={styles.btns}>
          <ButtonX size="small" color="#211913" variant="outlined" className={styles.actionBtn} disabled={!selectedProductIds.length} onClick={onClear}>Clear</ButtonX>
          <ButtonX size="small" className={styles.actionBtn} disabled={!selectedProductIds.length} onClick={onAdd}>{multiselect ? 'Add' : 'Select'}</ButtonX>
        </Box>
      </Box>
     </>
    </ModalBox>
  );
}

export default ProductSearch;