import { Box } from "@mui/material";
import styles from "./MultiInput.module.scss";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import { useEffect, useState } from "react";
import InputX from "components/atoms/InputX/InputX";
import { v4 as uuid } from 'uuid';
import InputDragger from "./InputDragger";


const getRandomKey = () => uuid();

export type MultiInputItem = {
  type: 'text' | 'number';
  value: string | number;
}

export type MultiInputPropType = {
  inputs: MultiInputItem[];
  onChange: (values: MultiInputItem[]) => void;
}

export type CurrentMultiInputItemType = MultiInputItem & {
  id: number | string;
}

const separator = " • ";

const MultiInput:React.FC<MultiInputPropType> = (props) => {
  const {inputs, onChange} = props;
  const [showInputForm, setShowInputForm] = useState<boolean>(false);
  const [currentInputs, setCurrentInputs] = useState<CurrentMultiInputItemType[]>([]);
  
  const renderedValues = inputs.map((input) => input.value).join(separator);

  const handleInputUpdates = () => {
    if(!showInputForm) return ;
    let updatedInputs = currentInputs.filter((input) => {
      if(input.type === 'text' && !(input.value as string).trim()){
        return false;
      }
      return true;
    });
    updatedInputs = updatedInputs.map((input) => {
      if(input.type === 'text') return {...input, value: (input.value as string).trim()}
      return input;
    });
    onChange(updatedInputs);
    setShowInputForm(false);
  }

  useEffect(() => {
    if(showInputForm){
      setCurrentInputs(inputs.map(input => ({...input, id: getRandomKey()})));
    }
  }, [showInputForm]);

  return (
    <ClickTooltip
      title={
        <InputDragger 
          onChange={setCurrentInputs}
          inputs={currentInputs}
        />
      }
      show={showInputForm}
      className={styles.popupStyle}
      showPopper={handleInputUpdates}
      placement={'bottom'}
    >
      <Box className={styles.inputWrapper}>
        <InputX value={renderedValues} />
        <Box className={styles.clickLayer}  onClick={() => setShowInputForm(true)} />
      </Box>
    </ClickTooltip>
  );
}

export default MultiInput;