import {ClickAwayListener } from "@mui/material";
import LightTooltip, { TooltipPlacementType } from "../../../components/atoms/LightTooltip/LightTooltip";

type PropType  = {
  title: React.ReactNode | JSX.Element;
  children: JSX.Element;
  showPopper: (show: boolean) => void;
  show: boolean;
  className?: string;
  placement?: TooltipPlacementType;
  wrapperClassName?: string;
}

const ClickTooltip:React.FC<PropType> = ({show, showPopper, children, title, className, placement, wrapperClassName}) => {

  return (
    <ClickAwayListener onClickAway={() => showPopper(false)}>
      <div className={wrapperClassName}>
        <LightTooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement={placement}
          open={show}
          title={title}
          className={className}
        >
          {children}
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}

export default ClickTooltip;