import { ProductType } from "slices/productSearchSlice";
import styles from "./ProductList.module.scss";
import { Box } from "@mui/material";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useCallback, useEffect, useState } from "react";
import Img from "components/atoms/Img/Img";
import CloseIcon from '@mui/icons-material/Close';
import ProductSearch from "components/organisms/ProductSearch/ProductSearch";
import update from "immutability-helper";
import ProductDargCard from "./ProductDragCard";
import { resetAuth } from "slices/authSlice";
import {fetchCollectionProductsApi, updateCollectionProductsApi, updateCollectionProducts } from "slices/collectionProductsSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import Loader from "components/atoms/Loader/Loader";

type ProductListPropType = {
  action: 'create' | 'edit';
  collectionId?: string;
}

type ProductItemPropType = ProductType & {
  onRemove: () => void;
}

const ProductItem:React.FC<ProductItemPropType> = (props) => {
  const {id, image, title, onRemove} = props;

  return (
    <Box key={id} className={styles.productItem}>
      <Img src={image || undefined} alt={title} className={styles.productImg} />
      <p className={styles.productTitle}>{title}</p>
      <CloseIcon onClick={onRemove} className={styles.closeIcon} />
    </Box>
  );
}


const ProductList:React.FC<ProductListPropType> = (props) => {
  const dispatch = useAppDispatch();
  const {action, collectionId} = props;
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false);
  const [changesApplied, setChangesApplied] = useState<boolean>(true);
  const [loaderActive, setLoaderActive] = useState<boolean>(false);
  const [rows, setRows] = useState<number>(15);

  const collectionProducts = useAppSelector((state: RootState) => state.collectionProducts.productsData?.data) || [];
  const collectionProductsCount = useAppSelector((state: RootState) => state.collectionProducts.productsData?.totalCount) || 0;


  const updateProducts = async (operation: 'add' | 'remove', productIds: (string | number)[]) => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      try {
        setLoaderActive(true);
        await dispatch(updateCollectionProductsApi({operation, collectionId, productIds, headers})).unwrap();
        setChangesApplied(true);
      } catch(e) {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
    }
  }


  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      try {
        await dispatch(fetchCollectionProductsApi({rows, collectionId, headers})).unwrap();
      } finally {
        setChangesApplied(false);
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
    }
  }


  useEffect(() => {
    if(action === 'edit' && changesApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [changesApplied]);

  const removeProduct = (ith: number) => {
    if(action === 'create'){
      const updatedProducts = collectionProducts.filter((_, idx) => idx !== ith);
      dispatch(updateCollectionProducts(updatedProducts));
    }else{
      updateProducts('remove', [collectionProducts[ith].id]);
    }
  }

  const addProducts = (newProducts: ProductType[]) => {
    if(action === 'create'){
      const existingProductIds: Set<string|number> = new Set();
      collectionProducts.forEach((product) => {
        existingProductIds.add(product.id);
      });
      const nonExistNewProducts = newProducts.filter((product) => !existingProductIds.has(product.id));
      dispatch(updateCollectionProducts([...collectionProducts, ...nonExistNewProducts]));
    }else{
      updateProducts('add', newProducts.map((product) => product.id));
    }
  }

  const moveCard = useCallback(
    (dragIndex:number, hoverIndex:number) => {
      return;
      const dragCard = collectionProducts[dragIndex];
      dispatch(updateCollectionProducts(
        update(collectionProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      ));
    },
    [collectionProducts]
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <p className={styles.heading}>Products in this collection</p>
        <p className={styles.productsCount}>{`${collectionProductsCount} Products`}</p>
        <ButtonX size="small" className={styles.actionBtn} onClick={() => setShowAddProduct(true)}>Add Products</ButtonX>
      </Box>
      <Loader show={loaderActive} />
      {!loaderActive && (
        <>
          <Box className={styles.productList}>
            {collectionProducts.map((product, idx) => (
              <ProductItem
                onRemove={() => removeProduct(idx)}
                {...product}
              />
            ))}
          </Box>
          {action === 'edit' && collectionProductsCount > collectionProducts.length &&(
            <ButtonX 
              size="small" 
              color="#211913" 
              variant="outlined" 
              onClick={() => {
                setRows((rows) => rows + 15);
                setChangesApplied(true);
              }}
            >
              View More
            </ButtonX>
          )}
          <ProductSearch
            multiselect
            open={showAddProduct}
            onChange={addProducts}
            onClose={() => setShowAddProduct(false)}
          />
        </>
      )}
    </Box>
  )
}

export default ProductList;