import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import styles from "./CollectionType.module.scss";
import WithLabel from "components/atoms/WithLabel/WithLabel";
import NamedToggle from "../NamedToggle/NamedToggle";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import { useDispatch } from 'react-redux';
import { CollectionConditionsApi, updateCollectionType, updateDisjunctive, updateRules } from "slices/collectionFormSlice";
import FilterGroup, { Filter } from "components/organisms/FilterGroup/FilterGroup";
import { resetAuth } from "slices/authSlice";
import { resetFilter } from "slices/filtersSlice";
import ProductList from "../ProductList/ProductList";
import { useParams } from "react-router-dom";
import { ProductType } from "slices/productSearchSlice";

// Define the type of the props
type PropType = {
  action: string;
};

// Function Component 
const CollectionType: React.FC<PropType> = ({
  action
}) => {

  // Define the component states here
  const [filters , setFilters] = useState<Filter[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);

  // Importing dispatch to call the redux actions/methods
  const dispatch = useAppDispatch();

  // Fetch the redux state and store it in a variable
  const collectionFormData = useAppSelector((state: RootState) => state.collectionForm.collectionForm);
  const collectionConditions = useAppSelector((state: RootState) => state.collectionForm.collectionConditions);
  
  const collection_type = (((collectionFormData?.collection_type == 'smart_collection') || collectionFormData?.collection_type == 'smart') ? 'smart_collection' : 'custom_collection')
  const header = (action == 'create') ? 'Collection Type*' : 'Conditions';

  // onChange functions to update the redux state
  const handleChangeCollectionType = (value: string) => {
    dispatch(updateCollectionType(value));
  };

  const handleChangeDisjunctive =  (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDisjunctive((event.target.value == 'true' ? true : false)));
  };

  const handleRulesChange =  (rules: Filter[]) => {
    dispatch(updateRules(rules));
  };

  // Method to fetch the collection Conditions
  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        CollectionConditionsApi({
          headers
        })
      ).unwrap();
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const { id } = useParams<{ id?: string }>();
  
  // Call fetchData once the component is rendered
  useEffect(() => {
    fetchData();
  }, []);

  // Return the collection type component div 
  return (
    <Box className={styles.CollectionType}>
      
      {<Box className={styles.header}> {header} </Box>}

      {action == 'create' && 
        (
          <NamedToggle
            trueLabel={"Smart"}
            trueLabelDescription={"Add products by using conditions"}
            falseLabel={"Manual"}
            falseLabelDescription={"Select product one by one"}
            value={collection_type === 'smart_collection'}
            onChange={handleChangeCollectionType}
          />
        )
      }

      {action == 'create' && <Divider className={styles.divider} />}

      {(collection_type === 'smart_collection') ? 
      
        <Box className={styles.smartCollection}>
          <Box className={styles.conditions}>
            <Box className={styles.header}>Products must match:</Box>
            <Divider orientation="vertical" />

            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={collectionFormData?.disjunctive}
              className={styles.radioGroup}
              onChange={handleChangeDisjunctive}
            >
              <FormControlLabel
                value={false}
                control={<Radio className={styles.customRadio} />}
                label="Match all conditions"
                className={styles.label}
              />
              <FormControlLabel
                value={true}
                control={<Radio className={styles.customRadio} />}
                label="Match any condition"
                className={styles.label}
              />
            </RadioGroup>
          </Box>

          <FilterGroup
            options={collectionConditions}
            filters={collectionFormData?.rules}
            onChange={handleRulesChange}
          />
        </Box>
        :
        <ProductList 
          action={action as 'create' | 'edit'}
          collectionId={id}
        />
      }
    </Box>
  );
};

export default CollectionType;


