import { useCallback, useEffect } from "react";
import styles from "./MultiInput.module.scss";
import { v4 as uuid } from 'uuid';
import { Box } from "@mui/material";
import { CurrentMultiInputItemType } from "./MultiInput";
import update from "immutability-helper";
import InputX from "components/atoms/InputX/InputX";
import CloseIcon from '@mui/icons-material/Close';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import InputCard from "./InputCard";
import SelectX from "components/atoms/SelectX/SelectX";

const getRandomKey = () => uuid();

export type InputDraggerPropType = {
  inputs: CurrentMultiInputItemType[];
  onChange: (values: CurrentMultiInputItemType[]) => void;
}

type InputItemPropType = {
  value: string | number;
  type: 'text' | 'number';
  onDelete: () => void;
  onChange: (value: string|number) => void;
}

const InputItem:React.FC<InputItemPropType> = (props) => {
  const {value, type, onChange, onDelete} = props;
  return (
    <Box className={styles.inputItem}>
      <InputX value={value} type={type} onChange={(e) => onChange(e.target.value)} />
      <CloseIcon onClick={onDelete} className={styles.closeIcon} />
    </Box>
  );
}

const InputDragger:React.FC<InputDraggerPropType> = (props) => {  
  const {inputs, onChange} = props;

  const handleAddItem = (type: 'text' | 'number' = 'text') => {
    let updatedValues:CurrentMultiInputItemType[] = [];
    if(type === 'text'){
      updatedValues =  [...inputs, {value: '' , type: 'text', id: getRandomKey()}];
    }else{
      updatedValues =  [...inputs, {value: 0 , type: 'number', id: getRandomKey()}]
    }
    onChange(updatedValues);
  }

  const removeItem = (ith: number) => {
    let updatdValues:CurrentMultiInputItemType[] = inputs.filter((_, idx) => idx !== ith);
    onChange(updatdValues);
  }

  const inputUpdate = (ith: number, value: string | number) => {
    const type = inputs[ith].type;
    let updatedValues:CurrentMultiInputItemType[] = inputs.map((input, idx) => {
      if(ith !== idx) return input;
      return {
        ...input,
        value: type === 'number' ? +value : value.toString(),
      }
    });
    onChange(updatedValues);
  }

  const moveCard = useCallback(
    (dragIndex:number, hoverIndex:number) => {
      const dragCard = inputs[dragIndex];
      onChange(
        update(inputs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [inputs]
  );

  return (
    <Box className={styles.inputDraggerPopup}>
      {inputs.length > 0 && 
        <Box className={styles.dragCards}>
          {inputs.map((input, ith) => (
            <InputCard
              index={ith}
              key={input.id}
              id={ith}
              moveCard={moveCard}
            >
              <InputItem
                value={input.value} 
                type={input.type}
                onChange={(value) => inputUpdate(ith, value)}
                onDelete={() => removeItem(ith)}
              />
            </InputCard>
          ))}
        </Box>
      }
      <ButtonX
        color={"#211913"} 
        variant='outlined' 
        size="small" 
        onClick={() => handleAddItem()}
      >
        Add Item
      </ButtonX>
    </Box>
  );
}

export default InputDragger;