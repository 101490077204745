import { Middleware } from "@reduxjs/toolkit";
import {
  showNotification,
  NotificationType,
} from "../slices/notificationSlice";
import {
  resetAuth
} from "../slices/authSlice";

export const axiosMiddleware: Middleware = ({ dispatch }) => (next) => async (action: any) => {
  if (action.type.endsWith("/rejected")) {
    const errorMessage = action.payload?.errors  || "An error occurred!";    

    dispatch(
      showNotification({
        type: NotificationType.Error,
        message: errorMessage,
      })
    );

    if(errorMessage.includes('Unauthorized')) {
      localStorage.removeItem("userInfo");
      dispatch(resetAuth())
    }
  } else if (action.type.endsWith("/fulfilled")) {
    const successMessage = action.payload?.message || "Success!";

    dispatch(
      showNotification({
        type: NotificationType.Success,
        message: successMessage,
      })
    );
  }

  return next(action);
};