import { Box, TextField } from "@mui/material";
import styles from "./InputX.module.scss";
import { TextFieldProps } from "@mui/material";
import { csx } from "helpers/utils";

export type InputXProps = Omit<TextFieldProps, 'error' | 'helperText'> & {
  error?: string;
  wrapperClass?: string;
  rows?: number;
}

const InputX: React.FC<InputXProps> = (props) => {
  const { label, required, error, className, wrapperClass, multiline = false, rows = 1, ...rest } = props;
  return (
    <Box className={csx(wrapperClass, styles.inputXWrapper)}>
      {!!label && (
        <Box className={styles.textLabel}>
          {label} {required && <sup>*</sup>}
        </Box>
      )}
      <TextField
        fullWidth
        multiline={multiline}
        rows={multiline ? rows : undefined}
        className={csx(className, styles.inputX)}
        error={!!error}
        helperText={error}
        {...rest}
      />
    </Box>
  );
}

export default InputX;