import { Box, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./MultiSelectX.module.scss";
import { csx } from "helpers/utils";
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";

type MultiSelectXPropType = {
  label?: JSX.Element | React.ReactNode;
  options: { label: JSX.Element | React.ReactNode, value: string }[]
  values: string[] | undefined;
  onChange: (value: string[]) => void;
  className?: string;
  wrapperClass?: string;
  required?: boolean;
  error?: string;
}

const MultiSelectX: React.FC<MultiSelectXPropType> = (props) => {
  const { required, label, options, values, onChange, className, wrapperClass, error } = props;

  const handleFilterSelection = (toggleValue: string) => {
    if(values?.includes(toggleValue)){
      onChange(values.filter((val) => val !== toggleValue))
    }else{
      onChange([...(values || []),  toggleValue]);
    }
  }

  return (
    <Box className={csx(wrapperClass, styles.selectXWrapper)}>
      {!!label && (
        <Box className={styles.textLabel}>
          {label} {required ? <sup>*</sup> : null}
        </Box>
      )}
      <FormControl fullWidth error={!!error}>
        <Select
          className={csx(className, styles.selectX)}
          fullWidth
          error={!!error}
          renderValue={() => options.filter((op) => values?.includes(op.value)).map((op) => op.label).join(" • ")}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((option) => {
            return <MenuItem key={option.value} value={option.value} dense className={styles.selectOption} onClick={(e) => {
              e.stopPropagation();
              handleFilterSelection(option.value)
            }} >
              <CheckboxFilter
                label={option.label}
                checked={values?.includes(option.value) || false}
                onChange={() => {}}
              />
            </MenuItem>
          })}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default MultiSelectX;