import { Box, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./SelectX.module.scss";
import { csx } from "helpers/utils";

type SelectXPropType = {
  label?: JSX.Element | React.ReactNode;
  options: { label: JSX.Element | React.ReactNode, value: string }[]
  value: string | undefined;
  onChange: (value: string) => void;
  className?: string;
  wrapperClass?: string;
  required?: boolean;
  error?: string;
}

const SelectX: React.FC<SelectXPropType> = (props) => {
  const { required, label, options, value, onChange, className, wrapperClass, error } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <Box className={csx(wrapperClass, styles.selectXWrapper)}>
      {!!label && (
        <Box className={styles.textLabel}>
          {label} {required ? <sup>*</sup> : null}
        </Box>
      )}
      <FormControl fullWidth error={!!error}>
        <Select
          className={csx(className, styles.selectX)}
          fullWidth
          error={!!error}

          value={value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((option) => {
            return <MenuItem key={option.value} value={option.value} dense className={styles.selectOption}>
              {option.label}
            </MenuItem>
          })}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>

    </Box>
  )
}

export default SelectX;