import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import styles from './VariantDetails.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectX from 'components/atoms/SelectX/SelectX';
import InputX from 'components/atoms/InputX/InputX';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import { VariantData, VariantRowData } from 'slices/productFormSlice';
import { useParams } from 'react-router-dom';
import EditProductVariant from '../EditProductVariant/EditProductVariant';

interface VariantDetailsPropType {
  variantDetails: VariantData;
  onChange: (field: keyof VariantData, value: any) => void;
  validationErrors: Record<keyof VariantData, string | undefined>;
}

const VariantDetails: React.FC<VariantDetailsPropType> = ({
  variantDetails,
  onChange,
  validationErrors,
}) => {
  const { id } = useParams<{ id?: string }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<string>(variantDetails.variantType || '');
  const [variantRows, setVariantRows] = useState<VariantRowData[]>(variantDetails.variantRowData || []);
  const [editingVariant, setEditingVariant] = useState<string | null>(null);

  const variantTypes = [
    { label: 'Size', value: 'size' },
    { label: 'Color', value: 'color' },
    { label: 'Material', value: 'material' },
  ];

  const handleSelectChange = (value: string) => {
    setSelectedVariant(value);
    onChange('variantType', value);
    const newRow = createNewRow();
    setVariantRows([newRow]);
    onChange('variantRowData', [newRow]);
  };

  const createNewRow = (): VariantRowData => ({
    variantName: '',
    skuId: '',
    costPrice: 0,
    mrp: 0,
    listingPrice: 0,
    stock: 0,
  });

  const addNewRow = () => {
    const newRow = createNewRow();
    const updatedRows = [...variantRows, newRow];
    setVariantRows(updatedRows);
    onChange('variantRowData', updatedRows);
  };

  const handleInputChange = (index: number, field: keyof VariantRowData, value: any) => {
    const updatedRows = [...variantRows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setVariantRows(updatedRows);
    onChange('variantRowData', updatedRows);
  };

  const handleNumberInputChange = (index: number, field: keyof VariantRowData, value: string) => {
    const numericValue = parseFloat(value) || 0;
    handleInputChange(index, field, numericValue);
  };

  const removeRow = (index: number) => {
    const updatedRows = variantRows.filter((_, i) => i !== index);
    setVariantRows(updatedRows);
    onChange('variantRowData', updatedRows);
  };

  const handleEditClick = (variantName: string) => {
    setEditingVariant(variantName);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingVariant(null);
    window.location.reload();
  };

  return (
    <Box className={styles.container}>
      {/* Row 1: Variant Type Selector */}
      <Box className={styles.variantType}>
        <SelectX
          label="Variant type"
          wrapperClass={styles.textField}
          options={variantTypes}
          value={selectedVariant}
          error={validationErrors.variantType}
          onChange={(value) => {
            validationErrors.variantType = '';
            handleSelectChange(value);
          }}
        />
      </Box>

      {/* Horizontal Line (SVG) below the dropdown */}
      {selectedVariant && (
        <>
          <Box className={styles.horizontalLine}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1336" height="2" viewBox="0 0 1336 2" fill="none">
              <path d="M0 1H1336" stroke="#E5E5E5" />
            </svg>
          </Box>

          {/* Label for Variant List */}
          <Box className={styles.labelLeft}>Variant List</Box>

          {/* Row 2: Variant List */}
          <Box className={styles.variantList}>
            {variantRows.map((row, index) => (
              <Box key={index} className={styles.variantRow}>
                <InputX
                  wrapperClass={styles.textField}
                  label="Variant Name"
                  placeholder="name"
                  error={""}
                  value={row.variantName}
                  onChange={(e) => handleInputChange(index, 'variantName', e.target.value)}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="SKU ID"
                  placeholder="SKU ID"
                  error={""}
                  value={row.skuId}
                  onChange={(e) => handleInputChange(index, 'skuId', e.target.value)}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Cost Price"
                  placeholder="Cost Price"
                  error={""}
                  value={row.costPrice}
                  onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="MRP"
                  placeholder="MRP"
                  error={""}
                  value={row.mrp}
                  onChange={(e) => handleNumberInputChange(index, 'mrp', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Listing Price"
                  placeholder="Listing Price"
                  error={""}
                  value={row.listingPrice}
                  onChange={(e) => handleNumberInputChange(index, 'listingPrice', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Stock"
                  placeholder="Stock"
                  error={""}
                  value={row.stock}
                  disabled
                />

                {id && (
                  <ButtonX variant="outlined" className={styles.editButton} color="#000" size="small" onClick={() => handleEditClick(row.variantName)}>
                    Edit
                  </ButtonX>
                )}

                {!id && (
                  <IconButton onClick={() => removeRow(index)} aria-label="delete" className={styles.deleteButton}>
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>

          <ButtonX variant="outlined" onClick={addNewRow} className={styles.addMoreBtn} color="#000" size="small">
            Add more
          </ButtonX>
        </>
      )}

      {isModalOpen &&
        <EditProductVariant
          open={isModalOpen}
          onClose={handleCloseModal}
          selectedVariant={editingVariant || ''}
        />
      }
    </Box>
  );
};

export default VariantDetails;
