import React from "react";
import { Box, Button } from "@mui/material";
import styles from "./CollectionFormHeader.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";

type CollectionFormHeaderProps = {
  label: string;
  onDiscard: () => void;
  onSave: () => void;
  stateUpdated: boolean;
};

const CollectionFormHeader: React.FC<CollectionFormHeaderProps> = ({
  label,
  onDiscard,
  onSave,
  stateUpdated,
}) => {
  return (
    <Box className={styles.collectionHeader}>
      <MainHeader label={label} allowBack={true}>
        <Box>
          <Button
            id="download-button"
            variant="outlined"
            className={styles.outlinedBtn}
            onClick={onDiscard}
            disabled={!stateUpdated}
          >
            Discard
          </Button>

          <Button
            id="download-button"
            variant="contained"
            className={styles.containedBtn}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </MainHeader>
    </Box>
  );
};

export default CollectionFormHeader;
