export const BACKEND_BASE_URL = "https://staging.vaaree.com";

export const ROUTES = {
  PRODUCTS: "/",
  COLLECTIONS: "/all_collections",
  EDIT_COLLECTION: "/collections/:id/edit",
  NEW_PRODUCT: "/products/new",
  EDIT_PRODUCT: "/products/:id/edit",
  REQUESTS: '/request-tracking'
};

export type DateRangeType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export type MonthYearType = {
  month: number | undefined;
  year: number | undefined;
};


export const DEFAULT_YELLOW_COLOR = '#FFC845';
export const DEFAULT_BLACK_COLOR = '#211913';
