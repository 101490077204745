import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import resetPasswordSlice from "./slices/resetPasswordSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import notificationReducer from "./slices/notificationSlice";
import userReducer from "./slices/userSlice";
import filtersSliceReducer from "./slices/filtersSlice";
import productCatalogueReducer from "./slices/productCatalogueSlice";
import collectionCatalogueReducer from "./slices/collectionCatalogueSlice";
import productSearchSlice from "slices/productSearchSlice";
import collectionFormSlice from "slices/collectionFormSlice";
import { axiosMiddleware } from "./api/middleware";
import productFormReducer from "slices/productFormSlice";
import collectionProductsSlice from "slices/collectionProductsSlice";
import requestTrackingSlice from "slices/requestTrackingSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    resetPassword: resetPasswordSlice,
    forgotPassword: forgotPasswordSlice,
    notification: notificationReducer,
    user: userReducer,
    productCatalogue: productCatalogueReducer,
    collectionCatalogue: collectionCatalogueReducer,
    productForm: productFormReducer,
    filters: filtersSliceReducer,
    searchProduct: productSearchSlice,
    collectionForm: collectionFormSlice,
    collectionProducts: collectionProductsSlice,
    trackRequest: requestTrackingSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
