import styles from "./EditProductVariant.module.scss";
import { Box } from "@mui/material";
import { csx } from "helpers/utils";

const VariantImageEdit = () => {
  const productVarintImage = "https://vaaree.com/cdn/shop/files/Untitled-1_0003_12569-__3.jpg?v=1724906332&width=700";

  return (
    <Box className={csx(styles.formSection, styles.variantImageForm)}>
      <p className={styles.formTitle}>Variant Image</p>
      <Box className={styles.imageWrapper}>
        <img src={productVarintImage} alt="Product Variant" />
      </Box>
    </Box>
  );
}
export default VariantImageEdit;