import React, { useState, useEffect } from "react";
import styles from "./BasicDetails.module.scss";
import { Chip, Box } from "@mui/material";
import { useAppSelector } from "hooks/redux-hooks";
import InputX from "components/atoms/InputX/InputX";
import SelectX from "components/atoms/SelectX/SelectX";
import ImageSelector from "components/organisms/ImageSelector/ImageSelector";
import { BasicProductFormData } from "slices/productFormSlice";

type BasicDetailsPropType = {
  basicFormData: BasicProductFormData;
  onChange: (field: keyof BasicProductFormData, value: any) => void;
  validationErrors: Record<keyof BasicProductFormData, string | undefined>;
};

const BasicDetails: React.FC<BasicDetailsPropType> = ({ basicFormData, onChange, validationErrors }) => {
  const [tagInput, setTagInput] = useState("");
  const [filteredSubCategories, setFilteredSubCategories] = useState<any[]>([]);

  // Fetching data from Redux state
  const vendorDetails = useAppSelector((state) => state.productForm.vendorDetails);
  const categories = useAppSelector((state) => state.productForm.categories);
  const setPieces = useAppSelector((state) => state.productForm.setPieces);
  const subCategories = useAppSelector((state) => state.productForm.subcategories);
  const productTypes = useAppSelector((state) => state.productForm.productTypes);

  const handleTagAdd = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && tagInput.trim() !== "") {
      event.preventDefault();
      onChange("tags", [...basicFormData.tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete: string) => {
    onChange(
      "tags",
      basicFormData.tags.filter((tag) => tag !== tagToDelete)
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.imageSection}>
        <ImageSelector
          onUpdateImage={(values) => {
            onChange("selectedImages", values);
          }}
          onUpdateAdditionalImage={(value) => {
            onChange("sizeChartImage", value);
          }}
          selectedImages={basicFormData.selectedImages}
          additionalImage={basicFormData.sizeChartImage}
          onRemoveSavedImage={ (id: number) => {
            onChange("removedImages", [...basicFormData.removedImages, id]);
          }}
          onRemoveSaveAdditionalImage={(id: number) => {
            onChange("removedImages", [...basicFormData.removedImages, id]);
          }}
        />
      </Box>

      <Box className={styles.formSection}>
        <Box className={styles.fullWidth}>
          {/* Title Input */}
          <InputX
            wrapperClass={styles.textField}
            label={"Title"}
            placeholder="Add Title"
            required
            value={basicFormData.title}
            error={validationErrors.title}
            onChange={(e) => {
              validationErrors.title = "";
              onChange("title", e.target.value)
            }}
          />

          {/* Description Input */}
          <Box className={styles.fullWidth}>
            <InputX
              wrapperClass={styles.textField}
              label={"Description"}
              placeholder="Add Description"
              multiline
              rows={4}
              value={basicFormData.description}
              onChange={(e) => onChange("description", e.target.value)}
            />
          </Box>

          {/* Row 1: Vendor & Set Of */}
          <Box className={styles.formRow}>
            <Box className={styles.halfWidth}>
              <SelectX
                wrapperClass={styles.textField}
                label={"Vendor"}
                required
                options={vendorDetails.map((vendorDetail) => ({
                  label: vendorDetail.name,
                  value: vendorDetail.id,
                }))}
                value={basicFormData.vendor}
                error={validationErrors.vendor}
                onChange={(value) => {
                  validationErrors.vendor = "";
                  onChange("vendor", value);
                }}
              />
            </Box>
            <Box className={styles.halfWidth}>
              <SelectX
                wrapperClass={styles.textField}
                label={"Set Of"}
                required={false}
                options={setPieces.map((setPiece) => ({
                  label: setPiece.name,
                  value: setPiece.id,
                }))}
                value={basicFormData.setOf}
                onChange={(value) => {
                  onChange("setOf", value);
                }}
              />
            </Box>
          </Box>

          {/* Row 2: Category, Sub-Category, Product Type */}
          <Box className={styles.formRow}>
            <Box className={styles.thirdWidth}>
              <SelectX
                wrapperClass={styles.textField}
                label={"Category"}
                required={true}
                options={categories.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                value={basicFormData.category}
                error={validationErrors.category}
                onChange={(value) => {
                  validationErrors.category = "";
                  onChange("category", value)
                }}
              />
            </Box>

            <Box className={styles.thirdWidth}>
              <SelectX
                wrapperClass={styles.textField}
                label={"Sub-Category"}
                required={true}
                options={subCategories.map((subCategory) => ({ label: subCategory.name, value: subCategory.id }))}
                value={basicFormData.subCategory}
                error={validationErrors.subCategory}
                onChange={(value) => {
                  validationErrors.subCategory = ""
                  onChange("subCategory", value)
                }}
              />
            </Box>

            <Box className={styles.thirdWidth}>
              <SelectX
                wrapperClass={styles.textField}
                label={"Product Type"}
                required={true}
                options={productTypes.map((productType) => ({
                  label: productType.name,
                  value: productType.id,
                }))}
                value={basicFormData.productType}
                error={validationErrors.productType}
                onChange={(value) => {
                  validationErrors.productType = ""
                  onChange("productType", value)
                }}
              />
            </Box>
          </Box>

          {/* Tags Input */}
          <Box className={styles.fullWidth}>
            <Box className={styles.chipContainer}>
              <InputX
                label={"Tags"}
                placeholder="Type to add tags.."
                required={true}
                value={tagInput}
                error={validationErrors.tags}
                onChange={(e) => {
                  validationErrors.tags = ""
                  setTagInput(e.target.value)
                }}
                onKeyDown={handleTagAdd}
              />
              {basicFormData.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                  className={styles.chip}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicDetails;
