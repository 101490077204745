import { Autocomplete, Box, TextField, TextFieldProps } from "@mui/material";
import styles from "./AutoCompleteX.module.scss";
import InputX, { InputXProps } from "../InputX/InputX";

type AutoCompleteXProps = InputXProps & {
  inputOptions?: string[];
}

const AutoCompleteX:React.FC<AutoCompleteXProps> = (props) => {
  const {inputOptions = [], ...rest } = props;

  return (
    <Autocomplete
      className={styles.autoCompleteX}
      freeSolo
      fullWidth
      disableClearable
      options={inputOptions}
      renderInput={(params) => (
        <InputX
          {...params}
          {...rest}
        />
      )}
    />
  );
}

export default AutoCompleteX;