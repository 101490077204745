import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, Fab,  Tab,  Tabs,  useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './CollectionCatalogue.module.scss';
import { setSort, collectionCatalogueApi, collectionCatalogueDownloadApi, CollectionDataHash } from "../../slices/collectionCatalogueSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "../../components/atoms/Loader/Loader";
import { Link, useNavigate} from "react-router-dom";

import { 
  resetFilter,
  setCollectionCatalogueSkuFilter,
  setCollectionCatalogueCollectionNameFilter,
  setcollectionCatalogueCreatedAtStartDateFilter,
  setcollectionCatalogueCreatedAtEndDateFilter,
  setCollectionCatalogueCollectionTypeFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import {collectionCatalogueColumns } from "./CollectionCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import SwipeableFilters from "../../components/organisms/SwipeableFilter/SwipeableFilter";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "../../components/atoms/CheckboxFilter/CheckboxFilter";

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateRangeType, ROUTES } from "../../constants";
import { ProductDataHash } from "../../slices/productCatalogueSlice";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";

const searchByOptions = [
  {id: 'collectionName', label: 'Collection Name'},
  {id: 'sku', label: 'SKU'},
]


const collectionTypeOptions = [
  {label: 'All', value: ''},
  {label: 'Smart', value: 'smart'},
  {label: 'Custom', value: 'custom'},
]

const CollectionCatalogue = () => {
  const navigate = useNavigate(); // Hook for navigation

  const dispatch = useAppDispatch();

  // Using theme to check the device is mobile or not
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  // Check whether the logged user is fbv user or not
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  
  // Get the collection data from store.
  const collectionCatalogueData = useAppSelector((state: RootState) => state.collectionCatalogue);
  const collectionCatalogueSkuFilter = useAppSelector((state: RootState) => state.filters.collectionCatalogueSkuFilter);
  const collectionCatalogueCollectionNameFilter = useAppSelector((state: RootState) => state.filters.collectionCatalogueCollectionNameFilter);
  const collectionCatalogueCollectionTypeFilter = useAppSelector((state: RootState) => state.filters.collectionCatalogueCollectionTypeFilter);
  const collectionCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.collectionCatalogueCreatedAtStartDateFilter);
  const collectionCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.collectionCatalogueCreatedAtEndDateFilter);

  let tableData:CollectionDataHash[] = []

  const handleSort = (column: keyof CollectionDataHash) => {
    const direction = column === collectionCatalogueData.sortedColumn && collectionCatalogueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(collectionCatalogueApi({
        page,
        rowsPerPage,
        collectionCatalogueSkuFilter,
        collectionCatalogueCollectionNameFilter,
        collectionCatalogueCreatedAtStartDateFilter,
        collectionCatalogueCreatedAtEndDateFilter,
        collectionCatalogueCollectionTypeFilter,
        headers,
      })).unwrap();
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any>  = () => dispatch(collectionCatalogueDownloadApi({
          collectionCatalogueSkuFilter,
          collectionCatalogueCollectionNameFilter,
          collectionCatalogueCreatedAtStartDateFilter,
          collectionCatalogueCreatedAtEndDateFilter,
          collectionCatalogueCollectionTypeFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setFilterApplied(true);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!collectionCatalogueSkuFilter
      ? [
        {
          id: "sku",
          label: "SKU",
          value: collectionCatalogueSkuFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!collectionCatalogueCollectionNameFilter
      ? [
        {
          id: "collectionName",
          label: "Collection Name",
          value: collectionCatalogueCollectionNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'sku': {
        dispatch(setCollectionCatalogueSkuFilter(value as string)); break;
      }
      case 'collectionName': {
        dispatch(setCollectionCatalogueCollectionNameFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setCollectionCatalogueSkuFilter(''));
    dispatch(setCollectionCatalogueCollectionNameFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setcollectionCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setcollectionCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Collection Master">   
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/collections';
          }}
        >
          Create New Collection
        </button>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <SelectFilter
            label="Collection Type"
            options={collectionTypeOptions}
            value={collectionCatalogueCollectionTypeFilter}
            onChange={(value) => {
              dispatch(setCollectionCatalogueCollectionTypeFilter(value));
              setTimeout(() => {setFilterApplied(true)}, 0);
            }}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={collectionCatalogueCreatedAtStartDateFilter}
            endDate={collectionCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>

        {!isMobile ? (
          <Button
            id="download-button"
            variant="outlined"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<CollectionDataHash>
          data={collectionCatalogueData?.collectionCatalogueData?.collections || []}
          columns={collectionCatalogueColumns()}
          sortedColumn={collectionCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={collectionCatalogueData.sortDirection}
          showPagination
          totalCount={collectionCatalogueData?.collectionCatalogueData?.total_count || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
          trProps={{
            onClick: ({original, row, id}) => {
              navigate(ROUTES.EDIT_COLLECTION.replace(':id', String(id)));
            },
            onHoverStyle: {
              cursor: 'pointer'
            }
          }}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={collectionCatalogueCreatedAtStartDateFilter}
        endDate={collectionCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default CollectionCatalogue;