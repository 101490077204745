import { Box } from '@mui/material';
import React, { useState } from 'react';
import styles from './ProductDetails.module.scss';
import SelectX from 'components/atoms/SelectX/SelectX';
import InputX from 'components/atoms/InputX/InputX';
import MultiInput from 'components/molecules/MultiInput/MultiInput';
import { useAppSelector } from 'hooks/redux-hooks';
import { RootState } from 'store';
import WithLabel from 'components/atoms/WithLabel/WithLabel';
import { ProductFormData, SpecificDetail } from 'slices/productFormSlice';
import MultiSelectX from 'components/atoms/MultiInputX/MultiSelectX';

const BooleanOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]


type ProductDetailsPropType = {
  formData: ProductFormData ;
  setFormData: (data: ProductFormData) => void;
}

const ProductDetails: React.FC<ProductDetailsPropType> = (props) => {

  const {formData, setFormData} = props;

  const specificDetails = formData.basicDetails.specificDetails || {};

  const specificOptions = useAppSelector((state: RootState) => state.productForm.specifiDetailOptions);

  const updateSpecificDetail = (key: string, namespace: string, value: string | null) => {

    const updatedSpecificDetail = specificDetails;
    specificDetails[`${namespace}.${key}`] = { key, namespace, value};

    setFormData({
      ...formData,
      basicDetails: {
        ...formData.basicDetails,
        specificDetails: updatedSpecificDetail
      }
    });
  }



  return (
    <Box className={styles.container}>

      {specificOptions.map((specificOption) => {
        if(['main_category', 'product_type', 'sub_category', 'set_pieces'].includes(specificOption.key)) return null;

        const value = specificDetails[`${specificOption.namespace}.${specificOption.key}`]?.value || '';

        if(specificOption.data_type === 'boolean'){
          return <SelectX
            key={`${specificOption.namespace}.${specificOption.key}`}
            wrapperClass={styles.textField}
            label={specificOption.label}
            options={BooleanOptions}
            value={value}
            onChange={(value) => {
              updateSpecificDetail(specificOption.key, specificOption.namespace, value)
            }}
          />
        }

        if(specificOption.data_type === 'number_integer'){
          return (
            <InputX
              key={`${specificOption.namespace}.${specificOption.key}`}
              wrapperClass={styles.textField}
              label={specificOption.label}
              type='number'
              value={+value}
              onChange={(e) => {
                updateSpecificDetail(specificOption.key, specificOption.namespace, e.target.value)
              }}
            />
          )
        }

        if(specificOption.data_type === 'single_line_text_field' && !specificOption.has_preset_values){
          return (
            <InputX
              key={`${specificOption.namespace}.${specificOption.key}`}
              wrapperClass={styles.textField}
              label={specificOption.label}
              value={value}
              onChange={(e) => {
                updateSpecificDetail(specificOption.key, specificOption.namespace, e.target.value)
              }}
          />
        )}

        if(specificOption.data_type === 'single_line_text_field' && specificOption.has_preset_values){
          const options = specificOption.master_values?.map((val) => ({label: val, value: val})) || [];
          return (
            <SelectX
              key={`${specificOption.namespace}.${specificOption.key}`}
              wrapperClass={styles.textField}
              label={specificOption.label}
              options={options}
              value={value}
              onChange={(val) => {
                updateSpecificDetail(specificOption.key, specificOption.namespace, val)
              }}
            />
          )
        }

        if(specificOption.data_type === 'list.single_line_text_field' && !specificOption.has_preset_values){
          
          let arrValues:string[] = [];
          try{
            arrValues = JSON.parse(value) as string[] || [];
          }catch(e){
            arrValues = [];
          }
          return (
            <WithLabel
              label={specificOption.label}
              labelPosition="top"
              gap={1}
            >
              <MultiInput
                key={`${specificOption.namespace}.${specificOption.key}`}
                inputs={arrValues.map((val) => ({type: 'text', value: val}))} 
                onChange={(val) => {
                  let strVal = JSON.stringify(val.map((v) => v.value));
                  updateSpecificDetail(specificOption.key, specificOption.namespace, strVal)
                }}
              />     
            </WithLabel>
          )
        }

        if(specificOption.data_type === 'list.single_line_text_field' && specificOption.has_preset_values){
          let arrValues:string[] = [];
          try{
            arrValues = JSON.parse(value) as string[] || [];
          }catch(e){
            arrValues = [];
          }
          return (
            <MultiSelectX 
              key={`${specificOption.namespace}.${specificOption.key}`}
              wrapperClass={styles.textField}
              label={specificOption.label}
              options={specificOption.master_values?.map((op) => ({label: op, value: op})) || []}
              values={arrValues}
              onChange={(values) => {
                let strVal = JSON.stringify(values);
                updateSpecificDetail(specificOption.key, specificOption.namespace, strVal)
              }}
            />
          )
        }

        return null;
      })}
    </Box >
  );
};

export default ProductDetails;
