import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import BasicDetails from './components/BasicDetails/BasicDetails';
import VariantDetails from './components/VariantDetails/VariantDetails';
import ProductDetailsTabs from './components/ProductDetailsTabs/ProductDetailsTabs';
import Loader from 'components/atoms/Loader/Loader';
import styles from './ProductForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  fetchVendorDetails,
  fetchCategories,
  fetchSubcategories,
  fetchProductTypes,
  fetchSetPieces,
  fetchColors,
  fetchReturnPolicies,
  fetchMaterials,
  fetchTags,
  ProductFormData,
  BasicProductFormData,
  VariantData,
  fetchProductDetails,
  updateProduct,
  createProduct,
  getSpecificDetailOptions,
  SpecificDetail,
} from 'slices/productFormSlice';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';

const PAGE_TITLE_NEW = "Add New Product";
const PAGE_TITLE_EDIT = "Edit Product";

const ProductForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const isEditMode = !!id;
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const { loading, productDetails } = useSelector(
    (state: RootState) => state.productForm
  );

  const initialFormData: ProductFormData = {
    basicDetails: {
      title: "",
      description: "",
      vendor: "",
      setOf: "",
      category: "",
      subCategory: "",
      productType: "",
      tags: [],
      selectedImages: [],
      sizeChartImage: undefined,
      removedImages: [],
      specificDetails: {},
    },
    variantDetails: {
      variantType: "",
      variantRowData: []
    }
  };

  const categories = useAppSelector((state) => state.productForm.categories) as {name: string, id: string | number}[];
  const subCategories = useAppSelector((state) => state.productForm.subcategories) as {name: string, id: string | number} [];
  const setPieces = useAppSelector((state) => state.productForm.setPieces) as {name: string, id: string | number} [];
  const productTypes = useAppSelector((state) => state.productForm.productTypes) as {name: string, id: string | number} [];
  const vendorDetails = useAppSelector((state) => state.productForm.vendorDetails) as {name: string, id: string | number} [];;
  
  const [originalFormData, setOriginalFormData] = useState<ProductFormData | null>(null);
  const [formData, setFormData] = useState<ProductFormData>(initialFormData);

  const handleBasicDetailsChange = (field: keyof BasicProductFormData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      basicDetails: {
        ...prev.basicDetails,
        [field]: value,
      },
    }));
  };

  const handleVariantDetailsChange = (field: keyof VariantData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      variantDetails: {
        ...prev.variantDetails,
        [field]: value,
      }
    }));
  };

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

        // Fetch common details like vendors, categories, etc.
        await dispatch(fetchVendorDetails({ headers })).unwrap();
        await dispatch(fetchCategories({ headers })).unwrap();
        await dispatch(fetchSubcategories({ headers })).unwrap();
        await dispatch(fetchProductTypes({ headers })).unwrap();
        await dispatch(fetchSetPieces({ headers })).unwrap();
        await dispatch(fetchColors({ headers })).unwrap();
        await dispatch(fetchReturnPolicies({ headers })).unwrap();
        await dispatch(fetchMaterials({ headers })).unwrap();
        await dispatch(fetchTags({ headers })).unwrap();

        // If in edit mode, fetch the existing product details
        if (isEditMode && id) {
          await dispatch(fetchProductDetails({ id, headers })).unwrap();  // Fetch product details for editing
        }
      } catch (error: any) {
        setFetchError(error.message || 'Failed to fetch data');
      } finally {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
      setLoaderActive(false);
    }
  };

  const fetchSpecificDetailOptions = async (id: string | number) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        const formData = { sub_category_id: id };
        await dispatch(getSpecificDetailOptions({ headers, formData })).unwrap();
      }catch(e) {
        console.error(e);
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
    }else{
      dispatch(resetAuth());
    }
  }

  useEffect(() => {
    fetchData();
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if(!!formData.basicDetails.subCategory){
      fetchSpecificDetailOptions(formData.basicDetails.subCategory);
    }
  }, [formData.basicDetails.subCategory]);

  const prepareSpecificDetailsData = (specificData: any) => {
    if(!specificData) return {};
    const res:Record<string, SpecificDetail> = {};
    if(specificData instanceof Array){
      specificData.forEach((data: SpecificDetail) => {
        res[`${data.namespace}.${data.key}`] = data;
      });
    }
    return res;
  }

  // Pre-fill the form when editing
  useEffect(() => {
    if (productDetails && isEditMode) {
      const formData:ProductFormData = {
        basicDetails: {
          title: productDetails.product?.title || "",
          description: productDetails.product?.description || "",
          vendor: productDetails.product?.vendor?.id || "",
          setOf: productDetails.product?.set_of?.id || "",
          category: productDetails.product?.category?.id || "",
          subCategory: productDetails.product?.sub_category?.id || "",
          productType: productDetails.product?.product_types?.id || "",
          tags: productDetails.product?.tags?.map((tag: any) => tag.name) || [],
          selectedImages: productDetails?.product?.primary_images || [],
          sizeChartImage: productDetails?.product?.size_chart_image,
          removedImages: [],
          specificDetails: prepareSpecificDetailsData(productDetails?.product?.specific_details),
        },
        variantDetails: {
          variantType: productDetails.product?.variant_type || "",
          variantRowData: productDetails.product?.variants || []
        }
      };
      setFormData(formData);
      setOriginalFormData(formData); // Set original form data for discard
    }
  }, [productDetails, isEditMode]);

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  if (fetchError) {
    return <p>Error: {fetchError}</p>;
  }

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.basicDetails.title) {
      errors.title = 'Title is required';
    }
    if (!formData.basicDetails.vendor) {
      errors.vendor = 'Vendor is required';
    }
    if (!formData.basicDetails.category) {
      errors.category = 'Category is required';
    }
    if (!formData.basicDetails.subCategory) {
      errors.subCategory = 'Sub-Category is required';
    }
    if (!formData.basicDetails.productType) {
      errors.productType = 'Product type is required';
    }
    if (formData.basicDetails.tags.length === 0) {
      errors.tags = 'Tag is required';
    }

    if (!formData.variantDetails.variantType) {
      errors.variantType = 'Variant type is required';
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const formatPayload = () => {
    const selectedCategory = categories.filter((category) => category.id === formData.basicDetails.category).map((category) => category.name)
    const selectedSubCategory = subCategories.filter((subCategory) => subCategory.id === formData.basicDetails.subCategory).map((subCategory) => subCategory.name)
    const setPieceValue = setPieces.find((setPiece) => setPiece.id === formData.basicDetails.setOf)?.name || '';
    const vendorValue = vendorDetails.find((vendor) => vendor.id === formData.basicDetails.vendor)?.name || '';
    const productValue = productTypes.find((productType) => productType.id === formData.basicDetails.productType)?.name || '';
    
    
    return {
      ...formData,
      basicDetails: {
        ...formData.basicDetails,
        vendor: vendorValue,
        productType: productValue
      },
      specificDetails: [
        {key: "main_category", namespace: "custom", value:  JSON.stringify(selectedCategory) },
        {key: "sub_category", namespace: "custom", value: JSON.stringify(selectedSubCategory) },
        {key: "set_pieces", namespace: "custom", value: setPieceValue },
        ...Object.values(formData.basicDetails.specificDetails),
      ]
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token;
        const headers = token
          ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
          : undefined;

        if (isEditMode) {
          await dispatch(updateProduct({ id: productDetails?.product?.id, headers, formData: formatPayload() }));
          window.location.reload();
        } else {
          await dispatch(createProduct({ formData: formatPayload(), headers }));
          window.location.replace('/');
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <form onSubmit={handleSubmit}>
        <Loader show={loaderActive} />
        {!loaderActive && !loading && (
          <>
            <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
              <button className={styles.secondaryBtn} onClick={handleDiscard}>Discard</button>
              <button type="submit" className={styles.primaryBtn}>{isEditMode ? 'Update' : 'Save'}</button>
            </MainHeader>

            <BasicDetails
              basicFormData={formData.basicDetails}
              onChange={handleBasicDetailsChange}
              validationErrors={validationErrors}
            />
            <VariantDetails
              variantDetails={formData.variantDetails}
              onChange={handleVariantDetailsChange}
              validationErrors={validationErrors}
            />
            <ProductDetailsTabs formData={formData} setFormData={setFormData} />
          </>
        )}
      </form>
    </Box>
  );
};

export default ProductForm;
