import styles from "./EditProductVariant.module.scss";
import { Box } from "@mui/material";
import { ProductVariantFormData } from "slices/productFormSlice";
import VariantNameEdit from "./VariantNameEdit";
import VariantImageEdit from "./VariantImageEdit";
import VariantPriceEdit from "./VariantPriceEdit";
import VariantInventoryEdit from "./VariantInventoryEdit";
import VariantMetafieldEdit from "./VariantMetafieldEdit";

type VariantDetailsProps = {
  variantDetail: ProductVariantFormData,
  onChange: (field: keyof ProductVariantFormData, value: any) => void;
};

const VariantDetails: React.FC<VariantDetailsProps> = ({ variantDetail, onChange }) => {
  return (
    <Box className={styles.variantDetails}>
      <Box className={styles.form}>
        <VariantNameEdit
          variantName={variantDetail.variantName}
          onVariantNameChange={onChange}
        />
        <VariantImageEdit />
        <VariantPriceEdit
          pricingProps={variantDetail.variantPricing}
          onVariantPricingChange={onChange}
        />
        <VariantInventoryEdit
          inventoryProps={variantDetail.variantInventory}
          onInventoryChange={onChange}
        />
        <VariantMetafieldEdit
          metafieldProps={variantDetail.metafields}
          onMetafieldChange={onChange}
        />
      </Box>
      {/* <Box className={styles.actionBtns}>
          <ButtonX color={"#211913"} variant="outlined" size="small" onClick={handleDelete}>
            Delete Variant
          </ButtonX>
          <Box>
            <ButtonX color={"#211913"} variant="outlined" size="small" onClick={handleClear}>
              Clear
            </ButtonX>
            <ButtonX color={"#3361FF"} variant="contained" className={styles.saveBtn} size="small" type="submit">
              Save
            </ButtonX>
          </Box>
        </Box> */}
    </Box>
  );
}

export default VariantDetails;