import Box from '@mui/material/Box';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from "../../../constants";
import { useLocation } from 'react-router-dom';
import styles from "./Navbar.module.scss";

const Navbar: React.FC = () => {
  const location = useLocation();

  const isSelected = (route: string) => {
    return location.pathname === route ? 'selected' : '';
  }

  return (
    <Box className={styles['nav-topbar-wrapper']}>
      <List className={styles['topbar-link-list']}>
        <ListItem className={`${styles['topbar-link']} ${isSelected(ROUTES.PRODUCTS) == 'selected' ? styles['selected'] : 'unselected'}`} component={Link} to={ROUTES.PRODUCTS}>
          <ListItemText primary="Products" className={styles['link-text']} />
        </ListItem>
        <ListItem className={`${styles['topbar-link']} ${styles[isSelected(ROUTES.COLLECTIONS)]}`} component={Link} to={ROUTES.COLLECTIONS}>
          <ListItemText primary="Collections" className={styles['link-text']} />
        </ListItem>
      </List>
    </Box>
  );
}

export default Navbar;
