import styles from "./EditProductVariant.module.scss";
import { Box, Divider } from "@mui/material";
import { csx } from "helpers/utils";
import { ProductDetailsData } from "slices/productFormSlice";


type VariantTypeViewProps = {
  productData: ProductDetailsData
  selectedVariant: string;
  onVariantTypeChange: (variant: string) => void;
}

const VariantTypeView: React.FC<VariantTypeViewProps> = ({ productData, selectedVariant, onVariantTypeChange }) => {
  const variantOptions = productData.product.variants.map((variant) => variant.variantName)
  return (
    <Box className={styles.variantView}>
      <img src={productData.product.primary_images[0]?.src} alt={'Product'} className={styles.variantImg} />
      <p className={styles.nameTxt}>{productData.product.title}</p>
      <Box className={styles.variantTypes}>
        <p className={styles.variantTitle}>
          Variant Type: <span>{productData.product.variant_type}</span>
        </p>
        <Divider sx={{ my: 1.5 }} />
        <Box className={styles.tabsWrapper}>
          {variantOptions.map((variant) => (
            <Box
              key={variant}
              onClick={() => onVariantTypeChange(variant)}
              className={csx(styles.tabItem, variant === selectedVariant ? styles.selected : null)}
            >
              {variant}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
export default VariantTypeView;