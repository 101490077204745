import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Fab, Tab, Tabs, useMediaQuery, useTheme, } from "@mui/material";
import { RootState } from "../../store";
import styles from "./ProductCatalogue.module.scss";
import { setSort, productCatalogueApi, productCatalogueDownloadApi, productStatusUpdateApi, bulkProductUploadApi, downBulkProductUploadFile } from "../../slices/productCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import FbvIcon from 'images/fbv-icon.png';
import {
  resetFilter,
  setProductCatalogueStatusFilter,
  ProductCatalogueStatusType,
  setProductCatalogueSkuFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueFbvEnabledFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueSubCategoryFilter,
  setProductCatalogueVendorFilter,
  setproductCatalogueCreatedAtStartDateFilter, 
  setproductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueInventoryFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { BULK_PRODUCT_UPLOAD_HEADER, productCatalogueColumns } from "./ProductCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import SwipeableFilters from "../../components/organisms/SwipeableFilter/SwipeableFilter";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import SearchByFilter, {
  AppliedFilterType,
  SearchSelections,
} from "../../components/molecules/SearchByFilter/SearchByFilter";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ProductDataHash } from "../../slices/productCatalogueSlice";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useNavigate } from "react-router-dom";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import { DateRangeType } from "../../constants";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import { NotificationType, showNotification } from "slices/notificationSlice";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";

const PAGE_TITLE = "Product Master";

const searchByOptions = [
  { id: "productName", label: "Product Name" },
  { id: "sku", label: "SKU" },
  { id: "vin", label: "VIN" },
];

const inventoryOptions = [
  {label: 'All', value: ''},
  {label: 'Out of Stock', value: 'out_of_stock'},
  {label: 'In Stock', value: 'in_stock'},
]

const ProductCatalogue = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [pageChanged, setPageChanged] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedIds, setSelectedIds] = React.useState<Set<string|number>>(new Set());
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState<boolean>(false);

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  const productCatalogueData = useAppSelector(
    (state: RootState) => state.productCatalogue
  );
  const productCatalogueStatusFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueStatusFilter
  );
  const productCatalogueCategoryFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueCategoryFilter
  );
  const productCatalogueSubCategoryFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueSubCategoryFilter
  );
  const productCatalogueVendorFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueVendorFilter
  );
  const productCatalogueTitleFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueTitleFilter
  );
  const productCatalogueSkuFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueSkuFilter
  );
  const productCatalogueVinFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueVinFilter
  );
  const productCatalogueHsnCodeFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueHsnCodeFilter
  );
  const productCatalogueFbvEnabledFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueFbvEnabledFilter
  );
  const productCatalogueCreatedAtStartDateFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueCreatedAtStartDateFilter
  );
  const productCatalogueCreatedAtEndDateFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueCreatedAtEndDateFilter
  );
  const productCatalogueInventoryFilter = useAppSelector(
    (state: RootState) => state.filters.productCatalogueInventoryFilter
  );

  const allProductCount =
    productCatalogueData.productCatalogueData?.all_products_count || 0;
  const activeProductCount =
    productCatalogueData.productCatalogueData?.published_products_count || 0;
  const draftProductCount =
    productCatalogueData.productCatalogueData?.draft_products_count || 0;
  const archivedProductCount =
    productCatalogueData.productCatalogueData?.archived_products_count || 0;

  let tableData: ProductDataHash[] = productCatalogueData.productCatalogueData?.product_variants || [];
  let totalCount: number = allProductCount;
  const updateTableData = () => {
    if (productCatalogueStatusFilter === "all") {
      totalCount = allProductCount;
    } else if (productCatalogueStatusFilter === "active") {
      totalCount = activeProductCount;
    } else if (productCatalogueStatusFilter === "draft") {
      totalCount = draftProductCount;
    } else if (productCatalogueStatusFilter === "archived") {
      totalCount = archivedProductCount;
    } else {
      totalCount = 0;
    }
  };
  updateTableData();

  const availableCategories = productCatalogueData.productCatalogueData?.categories || [];
  const categoryOptions = availableCategories.map((c) => ({ label: c, value: c, }));

  const availableSubCategories = productCatalogueData.productCatalogueData?.sub_categories || [];
  const subCategoryOptions = availableSubCategories.map((c) => ({ label: c, value: c, }));
  const availableVendors = productCatalogueData.productCatalogueData?.vendors || [];
  const vendorOptions = availableVendors.map((vendor) => ({ label: vendor, value: vendor, }));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!productCatalogueSkuFilter
      ? [
        {
          id: "sku",
          label: "SKU",
          value: productCatalogueSkuFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueTitleFilter
      ? [
        {
          id: "productName",
          label: "Product Name",
          value: productCatalogueTitleFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueVinFilter
      ? [
        {
          id: "vin",
          label: "VIN",
          value: productCatalogueVinFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueFbvEnabledFilter
      ? [
        {
          id: "fbvEnabled",
          label: "FBV",
          value: productCatalogueFbvEnabledFilter,
          type: "checkbox",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueCategoryFilter
      ? [
        {
          id: "category",
          label: "Category",
          value: productCatalogueCategoryFilter,
          type: "select",
          options: categoryOptions,
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueSubCategoryFilter
      ? [
        {
          id: "subcategory",
          label: "SubCategory",
          value: productCatalogueSubCategoryFilter,
          type: "select",
          options: subCategoryOptions,
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueVendorFilter
      ? [{
        id: "vendor",
        label: "Vendor",
        value: productCatalogueVendorFilter,
        type: "select",
        options: vendorOptions,
      }]
      : []) as AppliedFilterType[]),
    ...((!!productCatalogueInventoryFilter
      ? [{
        id: "inventory",
        label: "Inventory",
        value: productCatalogueInventoryFilter,
        type: "select",
        options: inventoryOptions,
      }]
      : []) as AppliedFilterType[]),
  ];

  const handleSort = (column: keyof ProductDataHash) => {
    const direction = column === productCatalogueData.sortedColumn && productCatalogueData.sortDirection === "asc" ? "desc" : "asc";
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    if (
      !!productCatalogueCategoryFilter &&
      !availableCategories.includes(productCatalogueCategoryFilter)
    ) {
      setProductCatalogueCategoryFilter("");
    }
  }, [productCatalogueData.productCatalogueData?.categories]);

  useEffect(() => {
    if (
      !!productCatalogueSubCategoryFilter &&
      !availableSubCategories.includes(productCatalogueSubCategoryFilter)
    ) {
      setProductCatalogueSubCategoryFilter("");
    }
  }, [productCatalogueData.productCatalogueData?.sub_categories]);

  useEffect(() => {
    if (
      !!productCatalogueVendorFilter &&
      !availableVendors.includes(productCatalogueVendorFilter)
    ) {
      setProductCatalogueVendorFilter("");
    }
  }, [productCatalogueData.productCatalogueData?.vendors]);

  const fetchData = async () => {
    console.log(rowsPerPage);
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        productCatalogueApi({
          page,
          rowsPerPage,
          productCatalogueCategoryFilter,
          productCatalogueSubCategoryFilter,
          productCatalogueVendorFilter,
          productCatalogueTitleFilter,
          productCatalogueSkuFilter,
          productCatalogueVinFilter,
          productCatalogueHsnCodeFilter,
          productCatalogueFbvEnabledFilter,
          productCatalogueStatusFilter,
          productCatalogueCreatedAtStartDateFilter,
          productCatalogueCreatedAtEndDateFilter,
          productCatalogueInventoryFilter,
          headers,
        })
      ).unwrap();
      setPageChanged(false);
      setFilterApplied(false);
      setLoaderActive(false);
      updateTableData();
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById("download-button");
    if (button) {
      if (!fab) button.innerHTML = "Downloading...";
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any> = () =>
          dispatch(
            productCatalogueDownloadApi({
              productCatalogueCategoryFilter,
              productCatalogueSubCategoryFilter,
              productCatalogueVendorFilter,
              productCatalogueTitleFilter,
              productCatalogueSkuFilter,
              productCatalogueVinFilter,
              productCatalogueHsnCodeFilter,
              productCatalogueFbvEnabledFilter,
              headers,
              productCatalogueStatusFilter,
              productCatalogueCreatedAtStartDateFilter,
              productCatalogueCreatedAtEndDateFilter,
              productCatalogueInventoryFilter,
              page,
              rowsPerPage,
            })
          );
        const success = await downloadFile({
          downloadApi,
          fileName: "report.csv",
          fileType: "text/csv",
        });
      } else {
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if (!fab) button.innerHTML = "Download";
    }
  };

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  useEffect(() => {
    if (filterApplied || pageChanged) {
      setSelectedIds(new Set());
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied, page]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1);
    setPageChanged(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const updateStatus = async (status: 'active' | 'draft' | 'archived') => {
    const userInfo = localStorage.getItem("userInfo");
    const selectedIdsArray = Array.from(selectedIds);
    if (userInfo) {
      try{
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload = {status: status, selected_ids: selectedIdsArray}
        await dispatch(productStatusUpdateApi({headers, payload}));
        setSelectedIds(new Set());
        setFilterApplied(true);
      }catch(e){
        setSelectedIds(new Set());
        setFilterApplied(true);
        console.error(e);
      }
    }
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setproductCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setproductCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(1);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const handleFilterValueChange = ({
    id,
    value,
  }: {
    id: string;
    value: string | boolean | string[];
  }) => {
    switch (id) {
      case "sku": {
        dispatch(setProductCatalogueSkuFilter(value as string));
        break;
      }
      case "productName": {
        dispatch(setProductCatalogueTitleFilter(value as string));
        break;
      }
      case "vin": {
        dispatch(setProductCatalogueVinFilter(value as string));
        break;
      }
      case "fbvEnabled": {
        dispatch(setProductCatalogueFbvEnabledFilter(value as boolean));
        break;
      }
      case "category": {
        dispatch(setProductCatalogueCategoryFilter(value as string));
        break;
      }
      case "subcategory": {
        dispatch(setProductCatalogueSubCategoryFilter(value as string));
        break;
      }
      case "vendor": {
        dispatch(setProductCatalogueVendorFilter(value as string));
        break;
      }
      case "inventory": {
        dispatch(setProductCatalogueInventoryFilter(value as string));
        break;
      }
    }
    setPage(1);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const handleSwipeableDrawerFilters = (
    idValueMap: Record<string, string | boolean | string[]>
  ) => {
    for (const [key, value] of Object.entries(idValueMap)) {
      switch (key) {
        case "fbvEnabled": {
          dispatch(setProductCatalogueFbvEnabledFilter(value as boolean));
          break;
        }
        case "category": {
          dispatch(setProductCatalogueCategoryFilter(value as string));
          break;
        }
      }
    }
    setPage(1);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const onSearchByClear = (id: string) => {
    
    if (id === "fbvEnabled") handleFilterValueChange({ id, value: false });
    else handleFilterValueChange({ id, value: "" });
  };

  const onSearchByAllClear = () => {
    dispatch(setProductCatalogueSkuFilter(""));
    dispatch(setProductCatalogueTitleFilter(""));
    dispatch(setProductCatalogueVinFilter(""));
    dispatch(setProductCatalogueFbvEnabledFilter(false));
    dispatch(setProductCatalogueCategoryFilter(""));
    dispatch(setProductCatalogueSubCategoryFilter(""));
    dispatch(setProductCatalogueVendorFilter(""));
    dispatch(setProductCatalogueInventoryFilter(""));
    setPage(1);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const downloadSampleFile = async () => {
    const userInfo = localStorage.getItem("userInfo");
    const selectedIdsArray = Array.from(selectedIds);
    if (userInfo) {
      try{
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(downBulkProductUploadFile({headers}));
        await downloadFile({downloadApi, fileName: 'Bulk Product Upload Sample.csv', fileType: 'text/csv'});
      }catch(e){
        console.error(e);
      }
    }
  }

  const bulkProductUpload = async (file: File) => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      try{
        dispatch(showNotification({message: 'Uploading file...', type: NotificationType.Info}));
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload  = new FormData();
        payload.append('csv_file', file);
        await dispatch(bulkProductUploadApi({headers, payload}));
      }catch(e){
        console.error(e);
      }
    }else{
      console.error('User info not found');
    }
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <button className={styles.secondaryBtn} onClick={() => {
          setShowBulkImportModal(true);
         }}>Import</button>
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/products/new';
          }}
        >
          Add New Product
        </button>

      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={productCatalogueStatusFilter}
          onChange={(_, newValue) => {
            setPage(1);
            dispatch(
              setProductCatalogueStatusFilter(
                newValue as ProductCatalogueStatusType
              )
            );
            setFilterApplied(true);
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#4F76FE",
            },
          }}
        >
          <Tab
            label={`All (${allProductCount})`}
            value={"all"}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color:
                productCatalogueStatusFilter === "all" ? "#3361FF" : "#262727",
            }}
          />
          <Tab
            label={`Active (${activeProductCount})`}
            value={"active"}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color:
                productCatalogueStatusFilter === "active" ? "#3361FF" : "#262727",
            }}
          />
          <Tab
            label={`Draft (${draftProductCount})`}
            value={"draft"}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color:
                productCatalogueStatusFilter === "draft" ? "#3361FF" : "#262727",
            }}
          />
          <Tab
            label={`Archive (${archivedProductCount})`}
            value={"archived"}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color:
                productCatalogueStatusFilter === "archived" ? "#3361FF" : "#262727",
            }}
          />
        </Tabs>
      </Box>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile ? (
            <>
              <SelectFilter
                label={"Vendor"}
                value={productCatalogueVendorFilter}
                options={vendorOptions}
                onChange={(val) => {
                  dispatch(setProductCatalogueVendorFilter(val));
                  setFilterApplied(true);
                }}
              />
              <SelectFilter
                label={"Category"}
                value={productCatalogueCategoryFilter}
                options={categoryOptions}
                onChange={(val) => {
                  dispatch(setProductCatalogueCategoryFilter(val));
                  setFilterApplied(true);
                }}
              />
              <SelectFilter
                label={"Subcategory"}
                value={productCatalogueSubCategoryFilter}
                options={subCategoryOptions}
                onChange={(val) => {
                  dispatch(setProductCatalogueSubCategoryFilter(val));
                  setFilterApplied(true);
                }}
              />
              <SelectFilter
                label={"Inventory"}
                value={productCatalogueInventoryFilter}
                options={inventoryOptions}
                onChange={(val) => {
                  dispatch(setProductCatalogueInventoryFilter(val));
                  setFilterApplied(true);
                }}
              />
              <DateRangeLabel
                noDateLabel={'Created At'}
                startDate={productCatalogueCreatedAtStartDateFilter}
                endDate={productCatalogueCreatedAtEndDateFilter}
                onClick={() => {setShowDateRangeFilter(true)}}
              />
              <CheckboxFilter 
                label={<img src={FbvIcon} alt="FBV Icon" />}
                checked={productCatalogueFbvEnabledFilter}
                onChange={(val) => {
                  dispatch(setProductCatalogueFbvEnabledFilter(val))
                  setFilterApplied(true);
                }}
              />
            </>
          ) : (
            <Fab
              variant="extended"
              size="small"
              className={styles.filterFAB}
              onClick={() => setFilterOpen(true)}
            >
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <ButtonX color="#000" variant="outlined"
            className={styles.downloadBtn}
            id="download-button"
            onClick={() => downloadReport()}
          >
            Download
          </ButtonX>
        ) : (
          <Fab
            className={styles.downloadFAB}
            onClick={() => downloadReport(true)}
            size="medium"
            id="download-button"
          >
            <FileDownloadOutlinedIcon fontSize="small" />
          </Fab>
        )}
      </Box>
      <SwipeableFilters
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        onAction={handleSwipeableDrawerFilters}
        checkboxFilters={[
          ...(fbv
            ? [
              {
                id: "fbvEnabled",
                label: "FBV",
                type: "checkbox",
                value: productCatalogueFbvEnabledFilter,
              } as {
                id: string;
                label: string;
                type: "checkbox";
                value: boolean;
              },
            ]
            : []),
        ]}
        selectFilters={[
          {
            id: "category",
            label: "Category",
            type: "select",
            value: productCatalogueCategoryFilter,
            options: categoryOptions,
          },
          {
            id: "subcategory",
            label: "SubCategory",
            type: "select",
            value: productCatalogueSubCategoryFilter,
            options: subCategoryOptions,
          },
          {
            id: "vendor",
            label: "Vendor",
            type: "select",
            value: productCatalogueVendorFilter,
            options: vendorOptions,
          },
          {
            id: "inventory",
            label: "Inventory",
            type: "select",
            value: productCatalogueInventoryFilter,
            options: inventoryOptions,
          },
        ]}
      />
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />

      {!!selectedIds.size && (
        <Box className={styles.productActionButtons}>
          {productCatalogueStatusFilter !== 'active' && (
            <ButtonX color="#000" variant="outlined" size="small" onClick={() => updateStatus('active')}>
              Mark as Active
            </ButtonX>
          )}
           {productCatalogueStatusFilter !== 'draft' && (
            <ButtonX color="#000" variant="outlined" size="small" onClick={() => updateStatus('draft')}>
              Mark as Draft
            </ButtonX>
          )}
           {productCatalogueStatusFilter !== 'archived' && (
            <ButtonX color="#000" variant="outlined" size="small" onClick={() => updateStatus('archived')}>
              Mark as Archived
            </ButtonX>
          )}
        </Box>
      )}

      {!loaderActive && (
        <TableManager<ProductDataHash>
          data={tableData.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)}
          totalCount={totalCount}
          columns={productCatalogueColumns(isMobile)}
          sortedColumn={productCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={productCatalogueData.sortDirection}
          showPagination
          currentPage={page - 1}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          showHeader={false}
          selectionProps={
            productCatalogueStatusFilter !== 'all' ? {
              selected: selectedIds,
              updateSelection: setSelectedIds,
            }: undefined
          }
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={productCatalogueCreatedAtStartDateFilter}
        endDate={productCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
      <FileUpload
        open={showBulkImportModal}
        label={"Bulk Product Upload"}
        sampleFileHandler={downloadSampleFile}
        fileType={['text/csv']}
        multiple={false}
        onDiscard={() => setShowBulkImportModal(false)}
        onSubmit={(files) => {
          bulkProductUpload(files[0]);
        }}
      />
    </Box>
  );
};

export default ProductCatalogue;
