import ModalBox from "components/atoms/ModalBox/ModalBox";
import styles from "./EditProductVariant.module.scss";
import { Box, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { RootState, AppDispatch } from "store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { ProductVariantFormData, updateProductVariant } from "slices/productFormSlice";
import VariantTypeView from "./VariantTypeView";
import VariantDetails from "./VariantDetails";

type EditProductVariantPropType = {
  open: boolean;
  onClose: () => void;
  selectedVariant: string;
}

const EditProductVariant: React.FC<EditProductVariantPropType> = ({ open, onClose, selectedVariant }) => {
  const dispatch: AppDispatch = useDispatch();
  const { productDetails } = useSelector((state: RootState) => state.productForm);

  const [selectedVariantDetail, setSelectedVariantDetail] = useState<any>(null);
  const [formData, setFormData] = useState<ProductVariantFormData | undefined>(undefined);
  const [originalFormData, setOriginalFormData] = useState<ProductVariantFormData | undefined>(undefined);

  // Fetch variant details when selectedVariant or productDetails changes
  useEffect(() => {
    if (productDetails && selectedVariant) {
      const variantDetail = productDetails.product.variants.find(
        (variant: any) => variant.variantName === selectedVariant
      );
      setSelectedVariantDetail(variantDetail);
    }
  }, [productDetails, selectedVariant]);

  // Initialize or update form data when selectedVariantDetail changes
  useEffect(() => {
    if (selectedVariantDetail) {
      const formData = {
        variantImage: "",
        variantName: selectedVariantDetail.variantName || "",
        variantPricing: {
          mrp: selectedVariantDetail.mrp || 0,
          listingPrice: selectedVariantDetail.listingPrice || 0,
          costPerItem: selectedVariantDetail.costPrice || 0,
          gst: selectedVariantDetail.gst || 0,
        },
        variantInventory: {
          skuID: selectedVariantDetail.skuId || "",
          hsnCode: selectedVariantDetail.hsnCode || 0,
          stockOnHand: selectedVariantDetail.stock || 0,
          vin: selectedVariantDetail.vin || "",
          isFBV: selectedVariantDetail.isFBV || false,
          trackQuantity: selectedVariantDetail.trackQuantity || false,
          sellOnOOS: selectedVariantDetail.sellOnOOS || false,
        },
        metafields: {
          upsell_product_variants: selectedVariantDetail.metafield?.upsell_product_variants || "",
          measurement: selectedVariantDetail.metafield?.measurement || "",
          includes: selectedVariantDetail.metafield?.includes || "",
          old_price: selectedVariantDetail.metafield?.old_price || 0,
        },
      };
      setFormData(formData);
      setOriginalFormData(formData);
    }
  }, [selectedVariantDetail]);

  // Handle form data change
  const handleFormDataChange = useCallback(
    (field: keyof ProductVariantFormData, value: any) => {
      setFormData((prev) => prev ? { ...prev, [field]: value } : undefined);
    },
    []
  );

  // Handle variant type change (select another variant)
  const handleVariantTypeChange = (variantName: string) => {
    const variantDetail = productDetails?.product?.variants.find(
      (variant: any) => variant.variantName === variantName
    );
    setSelectedVariantDetail(variantDetail);
  };

  // Submit the form data
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!formData) return;

    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token;
        const headers = token
          ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
          : undefined;
        const response = await dispatch(updateProductVariant({ id: selectedVariantDetail?.id, headers, formData }));
        if (response.meta.requestStatus === 'fulfilled') {
          onClose();
        } else {
          console.error('Failed to update product variant');
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFormData(originalFormData);
  }

  return (
    <ModalBox className={styles.wrapper} open={open} onClose={onClose}>
      {formData && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.header}>
            <p className={styles.title}>Edit Variant</p>
            <CloseIcon onClick={onClose} className={styles.closeIcon} />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box className={styles.formContainer}>
            <VariantTypeView
              productData={productDetails}
              selectedVariant={formData.variantName}
              onVariantTypeChange={handleVariantTypeChange}
            />
            <VariantDetails
              variantDetail={formData}
              onChange={handleFormDataChange}
            />
          </Box>

          <Box className={styles.actionBtns}>
            <ButtonX color="#211913" variant="outlined" size="small" onClick={handleDiscard} className={styles.clearBtn}>
              Discard
            </ButtonX>
            <ButtonX color="#3361FF" variant="contained" className={styles.saveBtn} size="small" type="submit">
              Save
            </ButtonX>
          </Box>
        </form>
      )}
    </ModalBox>
  );
};

export default EditProductVariant;
